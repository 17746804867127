import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/app.css';
import BackToTop from 'vue-backtotop';
import VueEasyLightbox from 'vue-easy-lightbox'
import VueGtag from "vue-gtag";


const feather = require('feather-icons');
feather.replace();

createApp(App)
	.use(router)
	.use(VueEasyLightbox)
	.use(BackToTop)
	.use(VueGtag, {
		appName: 'Portfolio',
		pageTrackerScreenviewEnabled: true,
		config: { id: "G-Z4H32R2740" },
		onReady() {
			console.log("GOOGLE ANALYTICS IS READY");
		}
	}, router)
	.mount('#app');

const appTheme = localStorage.getItem('theme');

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (
	appTheme === 'dark' &&
	document.querySelector('body').classList.contains('app-theme')
) {
	document.querySelector('body').classList.add('bg-primary-dark');
} else {
	document.querySelector('body').classList.add('bg-secondary-light');
}
