// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [

	{
		id: 1,
		title: 'Gotcha Back-End',
		category: 'Web Applicatie',
		img: require('@/assets/images/gotcha_cover.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Gotcha Back-End',
			singleProjectDate: '6 juni, 2021',
			singleProjectTag: 'Back-End',
		},
		projectImages: [
			{
				id: 1,
				title: 'Startscherm dashboard',
				img: require('@/assets/images/gotcha1.webp'),
			},
			{
				id: 2,
				title: 'Game settings',
				img: require('@/assets/images/gotcha2.webp'),
			},
			{
				id: 3,
				title: 'Speler settings',
				img: require('@/assets/images/gotcha3.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Voor het vak Full-stack op Odisee, kregen we een individuele opdracht om het "Gotcha" spel te realiseren. Voor de back-end moesten we een dashboard uitwerken, een API maken en documenteren die door de Front-End gebruikt word.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'HTML',
						'Bootstrap',
						'Blade',
						'Laravel',
						'Scribe',
						'Sanctum',
						'SQL'
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Gotcha is een spel waar je opdrachten in groep moet voltooien, iedere speler krijgt een target en een moordwapen. Eens ze hun opdracht voltooid hebben kunnen ze hun target uitschakelen. De speler krijgt dan zijn/haar target, zo blijft het spel doorgaan tot er 1 iemand over blijft.',
				},
				{
					id: 2,
					details:
						'Voor deze opdracht werd er gevraagd om een dashboard systeem uit te werken die door de admins beheerd kan worden. Een admin kan games activeren/wijzigen/verwijderen, hier kan de admin statistieken zien van de games. Ook kan hij/zij spelers wijzigen indien nodig of zelfs verwijderen. Er is ook een functie om te zien in welke games een bepaalde speler zit.',
				},
				{
					id: 3,
					details:
						'Daarnaast werd er ook gevraagd om een API uit te werken om games te wijzigen, verwijderen en te activeren. Uiteraard ook om spelers te wijzigen/verwijderen. Via de API kunnen (nieuwe) spelers registreren en in loggen.',
				},
				{
					id: 4,
					details:
						'Persoonlijk vond ik dit deel van de opdracht veel leuker omdat ik graag bezig ben aan dashboard systemen.',
				},
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Gerelateerde projecten',
			relatedProjects: [
				{
					id: 2,
					title: 'Gotcha Front-End',
					img: require('@/assets/images/gotchafrontend2.webp'),
				},
			],
		},

	},
	{
		id: 2,
		title: 'Gotcha Front-End',
		category: 'Web Applicatie',
		img: require('@/assets/images/gotchafrontend_cover.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Gotcha Front-End',
			singleProjectDate: '6 juni, 2021',
			singleProjectTag: 'Front-End',
		},
		projectImages: [
			{
				id: 1,
				title: 'Startscherm dashboard',
				img: require('@/assets/images/gotchafrontend2.webp'),
			},
			{
				id: 2,
				title: 'Game settings',
				img: require('@/assets/images/gotchafrontend3.webp'),
			},
			{
				id: 3,
				title: 'Speler settings',
				img: require('@/assets/images/gotchaFrontend.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Voor het vak Full-stack op Odisee, kregen we een individuele opdracht om het "Gotcha" spel te realiseren. Voor de Front-end moesten we een Single Page Application maken die in verbinding staat met de back-end API.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'Vuejs',
						'Vuex',
						'Vue Router',
						'Axios',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Gotcha is een spel waar je opdrachten in groep moet voltooien, iedere speler krijgt een target en een moordwapen. Eens ze hun opdracht voltooid hebben kunnen ze hun target uitschakelen. De speler krijgt dan zijn/haar target, zo blijft het spel doorgaan tot er 1 iemand over blijft.',
				},
				{
					id: 2,
					details:
						'Voor deze opdracht werd er gevraagd om 3 soorte profielen te maken: de speler, een spelbegeleider en een admin. Een speler kan games joinen zolang ze nog niet gestart zijn door een spelbegeleider of admin. Ze kunnen hun geschiedenis zien van vorige games en kunnen ook informatie over actieve games bekijken waar ze zelf niet inzitten. Een spelbegeleider zorgt ervoor dat er geen discussies mogelijk zijn in het spel, hij/zij speelt mee in het spel maar kan ook bepaalde spelers uitschakelen indien ze niet meewerken. Een admin kan games wijzigen/starten/verwijderen, spelbegeleiders aanduiden en zelf ook deelnemen aan een spel.',
				},
				{
					id: 3,
					details:
						'Qua design is dit project iets minder omdat mijn prioriteit meer lag bij de functionaliteiten van het spel en door tijdsgebrek.',
				},
			],
		},
		relatedProject: {
			relatedProjectsHeading: 'Gerelateerde projecten',
			relatedProjects: [
				{
					id: 1,
					title: 'Gotcha Back-End',
					img: require('@/assets/images/gotcha1.webp'),
				},
			],
		},

	},
	{
		id: 3,
		title: 'Sven\'s IJsjes (Coworking)',
		category: 'Web Applicatie',
		img: require('@/assets/images/sven_cover.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Sven\'s IJsjesshop',
			singleProjectDate: '18 januari, 2021',
			singleProjectTag: 'Back-End',
		},
		projectImages: [
			{
				id: 1,
				title: 'Homepagina',
				img: require('@/assets/images/sven.webp'),
			},
			{
				id: 2,
				title: 'Dashboard',
				img: require('@/assets/images/sven1.webp'),
			},
			{
				id: 3,
				title: 'Aankondigingen',
				img: require('@/assets/images/svne2.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Voor het vak Serverside moesten we in groep samen werken aan een website voor een fictieve klant. Hier was het vooral de bedoeling om samen aan een project te werken.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'PHP',
						'CSS & Bootstrap',
						'Twig',
						'Gitlab',
						'SQL'
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'"Sven" wilt een website waarbij zijn klanten kunnen zien wanneer hij op bepaalde plaatsen zijn ijsjes verkoopt. Dit hadden we gerealiseerd door een kalender. Hij wou liever een bestelformulier in plaats van een webshop. Hier duiden de klanten aan wanneer ze hun product willen afhalen. Ook moet er een mogelijkheid zijn om een offerte aan te vragen voor zijn ijskar.',
				},
				{
					id: 2,
					details:
						'Voor deze opdracht moet er ook een dashboard zijn met volgende functies: Een kalender bijhouden en wijzigen die op de publieke pagina staat, literverpakkingen/ijstaarten van de website halen als deze momenteel niet beschikbaar is en aankondigingen maken voor als hij bijvoorbeeld onverwachts gesloten is.',
				},
				{
					id: 3,
					details:
						'In het dashboardsysteem heb ik als extra de bestellingen bijgehouden, een overzicht van hoeveel bestellingen er deze maand gebeurd zijn, maandelijkse omzet, gemiddelde van een aankoop. Er is ook een tag systeem voor literverpakkingen/ijstaarten zoals bijvoorbeeld: Lactosevrij, Vegan... Er kunnen tags, producten en events bijgemaakt/gewijzigd/verwijderd worden. ',
				},
				{
					id: 4,
					details:
						'Bepaalde producten kunnen ook op de publieke pagina in de kijker gezet worden, in het dashboard systeem kan je deze makkelijk aanduiden zodat die tevoorschijn komt. ',
				},
			],
		},
		// relatedProject: {
		// 	relatedProjectsHeading: 'Gerelateerde projecten',
		// 	relatedProjects: [
		// 		{
		// 			id: 2,
		// 			title: 'Gotcha Front-End',
		// 			img: require('@/assets/images/gotchafrontend2.webp'),
		// 		},
		// 	],
		// },

	},
	{
		id: 4,
		title: 'Kamperen in VR',
		category: 'Virtual Reality',
		img: require('@/assets/images/kampereninvr.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Kamperen in VR',
			singleProjectDate: '26 mei, 2022',
			singleProjectTag: 'Virtual Reality',
		},
		projectImages: [
			{
				id: 1,
				title: 'Startscherm dashboard',
				img: require('@/assets/images/kampereninvr (1).webp'),
			},
			{
				id: 2,
				title: 'Game settings',
				img: require('@/assets/images/kampereninvr (2).webp'),
			},
			{
				id: 3,
				title: 'Speler settings',
				img: require('@/assets/images/kampereninvr (3).webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'https://www.youtube.com/watch?v=d4P7aXXNqW0',

				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Dit was een vrije opdracht waar we in groep een bepaald onderwerp mochten uitwerken, voor ons leek dit de ideale kans om eens iets in Virtual Reality te maken. ',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'Blender',
						'Unity',
						'C#',
						'XR Toolkit',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Kamperen in VR is tot stand gekomen door een vzw die gespecialiseerd is voor mensen met een beperking. Zij hadden een VR-headset aangekocht en hadden er graag iets mee gedaan. ',
				},
				{
					id: 2,
					details:
						'Eens het spel is opgestart start je op een vaste plaats dat uiteindelijk groeit tot een thuis. Door bepaalde taken uit te voeren komt er meer en meer een kamp gevoel rondom het startpunt. Je kan bijvoorbeeld hout hakken, stenen mijnen, muurklimmen, een kampvuur bouwen, een tent opzetten, koken, eten, gebieden ontdekken en speciale voorwerpen verzamelen. Je zal ook merken dat er een vos jou achterna loopt, deze draagt een rugzak waar je voorwerpen kunt insteken tijdens je ontdekking.',
				},
				{
					id: 3,
					details:
						'Omdat Virtual Reality nog altijd in zijn kinderschoenen staat is de XR Toolkit nog niet zo uitgebreid, de basisfuncties zoals sockets (hologram waar je objecten kunt in opslaan) zijn er, alleen ontbreekt er nog veel in mijn ogen. Zo heb ik dan de XR sockets uitgebreid voor het opbouwen van een tent/kampvuur, eens je het juiste object geplaatst heb komt deze niet meer los.',
				},
				{
					id: 4,
					details:
						'Het was een super leuk project, soms wat lastig om de Oculus Quest 2 te verwijderen en terug te plaatsen op het hoofd om te debuggen. ',
				},
			],
			video: "https://www.youtube.com/embed/d4P7aXXNqW0"
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
	{
		id: 5,
		title: 'PodNest (Project Solid uitproberen)',
		category: 'Web Applicatie',
		img: require('@/assets/images/solid.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'PodNest',
			singleProjectDate: '16 juni, 2022',
			singleProjectTag: 'Decentralized data',
		},
		projectImages: [
			{
				id: 1,
				title: 'PodNest',
				img: require('@/assets/images/solid.webp'),
			},
			{
				id: 2,
				title: 'Solid schema',
				img: require('@/assets/images/solid.svg'),
			},
			{
				id: 3,
				title: 'Solid schema',
				img: require('@/assets/images/solid2.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Voor het vak WebTopics-Advanced kon je een bepaald onderwerp kiezen waar je een presentatie voorstelde en een project uitwerkte. Ik had hiervoor Project Solid (Decentralized data) gekozen.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'Project Solid',
						'RDF',
						'Vite',
						'Tailwind',
						'Vue',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'"Solid is een webdecentralisatieproject onder leiding van Sir Tim Berners-Lee, de uitvinder van het World Wide Web.", Wat houdt dat nu precies in gedecentralizeerde data? Wel dat wilt eigenlijk zeggen dat jij zelf controle hebt over JOUW data. Zo kan je bepaalde apps op de ene dag toegang geven tot bijvoorbeeld jouw leeftijd en op de andere dag kan je zeggen "Ik deel mijn leeftijd niet meer" met dat bedrijf. Hoe dit te werk gaat? Iedereen heeft zijn eigen pod, je moet het zien als jouw eigen kluis van jouw persoonlijke data.',
				},
				{
					id: 2,
					details:
						'Wat ik nu gerealiseerd heb is een file viewer van jouw eigen pod, later zou ik bestanden willen uploaden/wijzigen/verwijderen. Per filetype komen er de geschikte logo\'s. Dit project is eigenlijk nog niet af, wanneer ik tijd over heb wil ik hier aan verder werken omdat het onderwerp echt interessant is. Het is dan ook de toekomst. ',
				},
				{
					id: 3,
					details:
						'Persoonlijk vindt ik de leercurve van Solid vrij moeilijk omdat de documentatie op dit moment niet zo ideaal is. Ook moet je LinkedData zeer goed onder de knie hebben.',
				},
			],
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
	{
		id: 6,
		title: 'EF-Bril',
		category: 'Web Applicatie',
		img: require('@/assets/images/efbril.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'EF-Bril',
			singleProjectDate: '6 juni, 2021',
			singleProjectTag: 'Front-end/Back-end',
		},
		projectImages: [
			{
				id: 1,
				title: 'EFBRIL startpagina',
				img: require('@/assets/images/efbril1.webp'),
			},
			{
				id: 2,
				title: 'Overzicht kinderen',
				img: require('@/assets/images/efbril3.webp'),
			},
			{
				id: 3,
				title: 'Vragenlijst',
				img: require('@/assets/images/efbril2.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Odisee hogeschool heeft de ambitie om samen met heel wat partners de zelfsturende vaardigheden (executieve functies) van kinderen, ook zij die opgroeien in een kwetsbaar gezin, te versterken. Dit project kreeg de naam “Zet je EF-bril op”.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'HTML',
						'CSS',
						'Express',
						'EJS',
						'SQL',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Wetenschappelijk onderzoek toont aan dat de jongste levensjaren cruciaal zijn, voor de cognitieve en sociaalemotionele ontwikkeling van kinderen. De opdracht was om hierrond een platform te bouwen waar begeleiders van kinderen kunnen evalueren aan de hand van een formulier met meerdere stappen.',
				},
				{
					id: 2,
					details:
						'Het probleem met zo een groot formulier is dat je niet alles in 1 keer kunt invullen (evaluatie kind). Hiervoor is er gebruik gemaakt van LocalStorage om deze tijdelijk op te slaan. Je kan de resultaten achteraf ook uitprinten.',
				},
				{
					id: 3,
					details:
						'Eens begeleiders zijn ingelogd zien ze een overzicht van de kinderen met de juiste klas. Wanneer ze die openen kunnen ze verder werken aan hun evaluatie.',
				},
			],
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
	{
		id: 7,
		title: 'At Issy\'s Naaishop',
		category: 'Web Applicatie',
		img: require('@/assets/images/naaishop_cover.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'At Issy\'s Naaishop',
			singleProjectDate: '6 juni, 2020',
			singleProjectTag: 'Front-end/Back-End',
		},
		projectImages: [
			{
				id: 1,
				title: 'Startpagina naaishop',
				img: require('@/assets/images/naaishop.webp'),
			},
			{
				id: 2,
				title: 'Game settings',
				img: require('@/assets/images/naaishop2.webp'),
			},
			{
				id: 3,
				title: 'Speler settings',
				img: require('@/assets/images/naaishop3.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'In mijn 7de jaar voor web hadden we als opdracht om een webshop te realiseren. Dit was de ideale kans om ondertussen aan een website te werken die effectief gebruikt ging worden in de praktijk.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'PHP',
						'Bootstrap',
						'SQL',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Voor dit project kon je bepaalde producten aankopen via de webshop, gebruikers kunnen registreren waar ze daarna hun email moeten verifiëren. Na het verifiëren kunnen ze inloggen en hun winkelmandje bekijken. Na de bestelling kunnen ze een PDF krijgen (die dynamisch gemaakt wordt) met hun factuur.',
				},
				{
					id: 2,
					details:
						'Daarnaast kunnen klanten ook contact opnemen met een formullier (met validatie), ook is er een mogelijkheid om nieuwsbrieven te versturen naar de klanten. Als ze dit niet meer willen kunnen ze hun ook uitschrijven.',
				},
				{
					id: 3,
					details:
						'Voor de eigenaar is er een dashboard waar ze klant details kunnen bekijken en eventueel kunnen aanpassen. Hetzelfde voor de producten. ',
				},
				{
					id: 4,
					details:
						'Dit project was pure PHP, moest ik dit project opnieuw willen maken zou ik dit met de technieken die ik op Odisee heb willen realiseren. Bijvoorbeeld: Laravel/Vue of een ander framework.',
				},
			],
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
	{
		id: 8,
		title: 'Back to the future',
		category: 'Design',
		img: require('@/assets/images/backtothefuture.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Back to the future',
			singleProjectDate: '21 november, 2018',
			singleProjectTag: 'Design',
		},
		projectImages: [
			{
				id: 1,
				title: 'Back to the future',
				img: require('@/assets/images/backtothefuturefull.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Dit was een opdracht waar we rond het thema "Back to the future" een voorblad moesten realiseren. ',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'Photoshop',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Voor ons klasboek waar iedereen in een historisch figuur verkleed was, werd er nog gevraagd om een voorblad te ontwerpen.',
				},
				{
					id: 2,
					details:
						'Als achtergrond had ik voor een post apocalyptische stad gekozen met de gedachte dat die samenleving terug naar het verleden wilt gaan. Bij "To" heb ik een portaal verwerkt waar ze met een raket uitkomen.',
				},
			],
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
	{
		id: 9,
		title: 'Expo Present - Stephan Vanfleteren (concept)',
		category: 'Design',
		img: require('@/assets/images/fomu.webp'),
		singleProjectHeader: {
			singleProjectTitle: 'Expo Present - Stephan Vanfleteren',
			singleProjectDate: '6 december, 2019',
			singleProjectTag: 'Design',
		},
		projectImages: [
			{
				id: 1,
				title: 'Poster Stephan Vanfleteren',
				img: require('@/assets/images/fumofull.webp'),
			},
		],
		projectInfo: {
			clientHeading: 'Over Project',
			companyInfos: [
				// {
				// 	id: 1,
				// 	title: 'Name',
				// 	details: 'Company Ltd',
				// },
				// {
				// 	id: 2,
				// 	title: 'Services',
				// 	details: 'UI Design & Frontend Development',
				// },
				// {
				// 	id: 3,
				// 	title: 'Website',
				// 	details: 'https://company.com',
				// },
				// {
				// 	id: 4,
				// 	title: 'Phone',
				// 	details: '555 8888 888',
				// },
			],
			objectivesHeading: 'Doel',
			objectivesDetails:
				'Voor een opdracht werd er gevraagd om een poster te designen, met zelfgemaakte foto\'s van de Expo Present - Stephan Vanfleteren.',
			technologies: [
				{
					title: 'Tools & Technologieën',
					techs: [
						'Photoshop',
					],
				},
			],
			projectDetailsHeading: 'Opgave',
			projectDetails: [
				{
					id: 1,
					details:
						'Stephan Vanfleteren is een Belgische fotograaf die zich vooral bezighoudt met portretten. Zo heeft hij al van bekende filmsterren, onder andere mads mikkelsen portretten genomen. Hij fotografeert mensen zoals ze echt zijn.',
				},
				{
					id: 2,
					details:
						'Deze poster heb ik gerealiseerd aan de hand van masking, double exposure en te spelen met kleuren.',
				},
			],
		},
		relatedProject: {
			// relatedProjectsHeading: 'Gerelateerde projecten',
			// relatedProjects: [
			// 	{
			// 		id: 2,
			// 		title: 'Gotcha Front-End',
			// 		img: require('@/assets/images/gotchafrontend2.webp'),
			// 	},
			// ],
		},
	},
];

export default projects;
